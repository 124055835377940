export const HOME = "/";
export const CONTACT = "/contact-us";
export const SOLUTIONS = "/solutions";

export const PRIVACY = "/privacy";
export const PRIVACY_LEGACY = "/privacy-policy";
export const PRIVACY_APP = "/app/privacy";

export const TERMS = "/terms";
export const TERMS_LEGACY = "/user-agreement";
export const TERMS_APP = "/app/terms";

export const AUTH_ACTION = "/_/auth/action";
