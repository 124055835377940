import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#984F88",
    },
    secondary: {
      main: "#522145",
    },
    error: {
      main: "#F86C6B", //#BC555C
    },
    warning: {
      main: "#ECAB45",
    },
    info: {
      main: "#90C2D3",
    },
    success: {
      main: "#75b976",
    },
    common: {
      gray: "#e0e0e0",
    },
  },
});

export default theme;
