export const SUPPORT_EMAIL = "support@payandconnect.co.za";

export const PC_APP = process.env.GATSBY_PC_APP;

export const SLACK_URL = process.env.GATSBY_SLACK_URL;

export const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;
export const RECAPTCH_URL = process.env.GATSBY_RECAPTCH_URL;

export const LOGIN = PC_APP + "/login";
export const SIGNUP = PC_APP + "/signup";

export const APP_STORE =
  "https://apps.apple.com/us/app/pay-connect/id1450933885";
export const GOOGLE_PLAY =
  "https://play.google.com/store/apps/details?id=com.getslideapp.pac";
export const APP_GALLERY = "https://appgallery.huawei.com/#/app/C104447231";

export const UCT = "https://www.uct.ac.za/";
export const STATUS_PAGE = "https://status.payandconnect.co.za/";
export const SUPPORT = "http://support.payandconnect.co.za";

export const STATUS_PAGE_URL =
  "https://zc0kcyzv912v.statuspage.io/embed/script.js";
export const ZENDESK_URL =
  "https://static.zdassets.com/ekr/snippet.js?key=18e6f6bb-ac61-407c-99f1-ce61d309c0c7";
