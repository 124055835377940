import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import useScript from "react-script-hook";

import { PRIVACY_LEGACY, TERMS_LEGACY } from "../../src/constants/routes";
import { STATUS_PAGE_URL, ZENDESK_URL } from "../../src/constants/vars";
import theme from "../../src/theme";

export default function TopLayout(props) {
  useScript({
    src: ZENDESK_URL,
    defer: true,
    id: "ze-snippet",
    onload: () => {
      if (
        window.location.pathname.includes("app") ||
        window.location.pathname.includes(PRIVACY_LEGACY) ||
        window.location.pathname.includes(TERMS_LEGACY)
      ) {
        window.zE("webWidget", "hide");
      }
    },
  });

  useScript({
    src: STATUS_PAGE_URL,
    defer: true,
  });

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
