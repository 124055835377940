/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react";

import TopLayout from "./TopLayout";

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
